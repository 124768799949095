import React, {Component} from 'react';


class BlogArticle extends Component {
    render() {
        return (
            <>
                <p>Blog article</p>
            </>
        );
    }
}

export default BlogArticle;